/* eslint-disable */
import httpClient from '@/utils/http-client';
import Cookies from 'universal-cookie';

const resource = 'auth';
const cookies = new Cookies();

const authApi = {
  async login(data) {
    try {
      const res = await httpClient({
        url: `${resource}/login`,
        method: 'POST',
        data,
      });
      return res.data;
    } catch (error) {
      window.location.href = process.env.REACT_APP_INTRA_URL;
      cookies.set('alert', "Skills", { domain: process.env.REACT_APP_SUB_DOMAIN })
    }
  },

  async getCurrentUser() {
    const res = await httpClient({
      url: `${resource}`,
      method: 'GET',
    });
    return res.data;
  },
};

export default authApi;
