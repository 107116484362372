/* eslint-disable */
import React, { useEffect } from 'react'
import ToastMessage from '@/components/common/ToastMessage'
import { ThemeProvider } from '@emotion/react'
import theme from '@/mui/theme'
import { BrowserRouter as Router, useRoutes, useNavigate, useLocation } from 'react-router-dom'

import UIContext from '@/context/uiContext'
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material'
import Error from '@/view/Error'
import { UserRole } from '@/config/app'
import useUIStore, { ACTIONS } from './store/uiStore'
import ConfirmDialog from './components/common/ConfirmDialog'
import AuthProvider from './components/guard/AuthProvider'
import ProtectedRoute from './components/guard/ProtectedRoute'
import GuestLayout from './components/layout/GuestLayout'
import Cookies from 'universal-cookie'

const SpiderDiagram = React.lazy(() => import('./view/Diagram'))
const CV = React.lazy(() => import('./view/CV'))
const Login = React.lazy(() => import('./view/Login'))
const Index = React.lazy(() => import('./view/Index'))
const Question = React.lazy(() => import('./view/Survey/Index'))
const CreateQuestion = React.lazy(() => import('./view/Survey/Create'))
const Profile = React.lazy(() => import('./view/Profile/Index'))
const TeamView = React.lazy(() => import('./view/Team/index'))
const QualityView = React.lazy(() => import('./view/Quality/index'))
const TeamDetail = React.lazy(() => import('./view/Team/ViewTeam'))
const QualityDetail = React.lazy(() => import('./view/Quality/QualityDetail'))
const ShareTeamDetail = React.lazy(() => import('./view/Team/ShareTeam'))
const QualityControll = React.lazy(() => import('./view/Team/QualityControll'))
const QualityControlDetail = React.lazy(() => import('./view/Team/QualityControlDetail'))

const MainView = () => {
  const navigate = useNavigate()
  const cookies = new Cookies()
  const location = useLocation()

  const handleSetCookie = () => {
    if(!cookies.get("isSignedOut")) {
      cookies.set("from", "SK", { domain: process.env.REACT_APP_SUB_DOMAIN })
    }
    handleRedirect()
  };

  const handleRedirect = () => {
    window.location.href = process.env.REACT_APP_INTRA_URL
  }

  useEffect(() => {
    if (location.pathname.includes('/view-only-team')) {
      return
    }
    if(!cookies.get("email")) {  
      navigate("/login");
      handleSetCookie()
    }
  }, []);

  const routes = useRoutes([
    { path: '/', element: <ProtectedRoute><Index /></ProtectedRoute>, index: true },
    { path: '/survey',
      children: [
        { path: 'create', element: <ProtectedRoute><CreateQuestion /></ProtectedRoute> },
        { path: ':id', element: <ProtectedRoute><CreateQuestion /></ProtectedRoute> },
        { path: '', element: <ProtectedRoute><Question /></ProtectedRoute> },
      ] },
    {
      path: '/team',
      children: [
        { path: '', element: <ProtectedRoute roles={[UserRole.HR, UserRole.ADMIN]}><TeamView /></ProtectedRoute> },
        { path: ':id', element: <ProtectedRoute><TeamDetail /></ProtectedRoute> },
        { path: ':id/quality', element: <ProtectedRoute><QualityControll /></ProtectedRoute> },
        { path: ':id/quality/:name', element: <ProtectedRoute><QualityControlDetail /></ProtectedRoute> },
      ]
    },
    {
      path: '/quality',
      children: [
        { path: '', element: <ProtectedRoute roles={[UserRole.HR, UserRole.ADMIN]}><QualityView /></ProtectedRoute> },
        { path: ':id', element: <ProtectedRoute><QualityDetail /></ProtectedRoute> },
      ]
    },
    { path: 'spider-diagram', element: <ProtectedRoute><SpiderDiagram /></ProtectedRoute>, },
    { path: 'cv', element: <ProtectedRoute roles={[UserRole.HR, UserRole.EMPLOYEE]}><CV /></ProtectedRoute>, },
    { path: 'error', element: <Error /> },
    { path: 'profile',
      children: [
        { path: 'create', element: <ProtectedRoute><CV /></ProtectedRoute> },
        { path: ':id', element: <ProtectedRoute><CV isEdit /></ProtectedRoute> },
        { path: ':id/spider-diagram', element: <ProtectedRoute><SpiderDiagram /></ProtectedRoute> },
        { path: '', element: <ProtectedRoute><Profile /></ProtectedRoute> },
      ] },
    { path: 'login', element: <GuestLayout><Login /></GuestLayout> },
    {
      path: '/view-only-team',
      children: [
        { path: ':id', element: <GuestLayout><ShareTeamDetail /></GuestLayout> },
      ]
    }
  ])
  return routes
}

function App() {
  const { state, dispatch } = useUIStore()

  const handleClose = () => {
    dispatch({
      type: ACTIONS.toggleLoading,
      payload: true,
    })
  }

  return (
    <Router>
      <UIContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        <ToastMessage
          message={state.toastMessage.message}
          type={state.toastMessage.type}
          open={state.toastMessage.message !== ''}
          handleClose={() =>
            dispatch({
              type: ACTIONS.setToastMessage,
              payload: { message: '' },
            })
          }
        />
        <ConfirmDialog
          open={state.confirmDialog.open}
          refreshData={state.confirmDialog.refreshData}
          callback={state.confirmDialog.callback}
        />
        <ThemeProvider theme={theme}>
          <React.Suspense fallback={(
            <Backdrop
              sx={{ color: '#fff', zIndex: (t) => t.zIndex.drawer + 1 }}
              open
              onClick={handleClose}
            >
              <CircularProgress color="primary" />
            </Backdrop>
            )}
          >
            {state.loading && (
              <Snackbar open={state.loading} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert
                  icon={<CircularProgress color="primary" size="1.5rem" />}
                  severity="info"
                  sx={{ width: '100%' }}
                >
                  Loading
                </Alert>
              </Snackbar>
            )}
            <AuthProvider>
              <MainView />
            </AuthProvider>
          </React.Suspense>
        </ThemeProvider>
      </UIContext.Provider>
    </Router>
  )
}

export default App
