/* eslint-disable */
import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import Logo from '@/assets/images/peppermint.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { PersonOutlineOutlined } from '@mui/icons-material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { UserRole } from '@/config/app'
import { MenuItem, MenuList, Paper, Popper, Tab, Tabs } from '@mui/material'
import useAuth from '@/hooks/auth'
import Cookies from 'universal-cookie'

function AppBar() {
  const cookies = new Cookies()
  const navigate = useNavigate()
  const location = useLocation()
  const { state, logout } = useAuth()
  const menuItems = [
    {
      title: 'Spider diagram',
      key: 'spider-diagram',
      path: '/spider-diagram',
      roles: [UserRole.EMPLOYEE],
    },
    {
      title: 'Quiz',
      key: 'survey',
      path: '/survey',
      roles: [UserRole.ADMIN, UserRole.HR],
    },
    {
      title: 'Profile',
      key: 'profile',
      path: '/profile',
      roles: [UserRole.ADMIN, UserRole.HR],
    },
    {
      title: 'Profile',
      key: 'cv',
      path: '/cv',
      roles: [UserRole.EMPLOYEE],
    },
    {
      title: 'Team',
      key: 'team',
      path: '/team',
      roles: [UserRole.ADMIN, UserRole.HR],
    },
    {
      title: 'Quality',
      key: 'qual',
      path: '/quality',
      roles: [UserRole.ADMIN, UserRole.HR],
    },
  ]

  const options = ['Log Out']

  const menuItemsByRole = React.useMemo(() =>
    menuItems.filter((menu) => state.user && menu.roles.includes(state.user.role)),
  [state.user]
  )

  const defaultRoute = useMemo(() => {
    if (location.pathname === '/') {
      return (state.user?.role === UserRole.HR) ? '/survey' : '/spider-diagram'
    }
    return location.pathname + location.search
  }, [state.user])

  const [currentRoute, setCurrentRoute] = React.useState(menuItemsByRole.find(
    (menu) => location.pathname.includes(menu.key))?.path || '/')
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleChange = (_event, newValue) => {
    navigate(newValue)
    setCurrentRoute(newValue)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  function onLogout() {
    localStorage.clear()

    cookies.remove('email', { domain: process.env.REACT_APP_SUB_DOMAIN });
    cookies.remove('password', { domain: process.env.REACT_APP_SUB_DOMAIN }); 
    cookies.remove('token', { domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.remove('accessToken', { domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.remove('user_role', { domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.remove('*', { path: '/', domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.set("isSignedOut", true, { path: "/", domain: process.env.REACT_APP_SUB_DOMAIN });

    logout()
    navigate('/login')
  }

  useEffect(() => {
    setCurrentRoute(menuItemsByRole.find(
      (menu) => location.pathname.includes(menu.key))?.path || currentRoute)
  }, [menuItemsByRole])

  useEffect(() => {
    setCurrentRoute(menuItemsByRole.find(
      (menu) => location.pathname.includes(menu.key))?.path || location.pathname)
  }, [location.pathname])

  return (
    <div>
      <div className="h-20 bg-white shadow-sm px-10 flex justify-between items-center">
        <div className="flex items-center">
          <a href='https://intra.peppermint.company'><img src={Logo} alt="logo" className="mr-16" /></a>
          <div className="flex space-x-4">
            <Tabs
              value={currentRoute === '/' ? defaultRoute : currentRoute}
              onChange={handleChange}
              aria-label="Menu"
            >
              {menuItemsByRole
                .map((menu) => (
                  <Tab key={menu.key} value={menu.path} label={menu.title} />
                ))}
            </Tabs>
          </div>
        </div>
        <div className="flex">
          <PersonOutlineOutlined className="mr-6" />
          <button className=" text-primary text-lg font-bold uppercase cursor-pointer" aria-describedby={id} type="button" onClick={handleClick}>
            { state.user?.email || 'Guest'}
          </button>
          <Popper id={id} open={open} anchorEl={anchorEl}>
            <Paper>
              <MenuList id="split-button-menu" autoFocusItem>
                {options.map((option) => (
                  <MenuItem key={option} onClick={onLogout}>
                    {option}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Popper>
        </div>
      </div>
    </div>
  )
}
function AppFooter() {
  return (
    <footer className="w-full bg-white border-t border-gray flex items-center">
      <div className="px-36 py-4 text-sm text-black">
        ©2022 Peppermint Company
      </div>
    </footer>
  )
}

function MainLayout({ children }) {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <AppBar />
      <div className="min-h-viewScreen">
        <div>{children}</div>
      </div>
      <AppFooter />
    </LocalizationProvider>
  )
}

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default MainLayout
