import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import UIContext from '@/context/uiContext'
import { ACTIONS } from '@/store/uiStore'
import PropTypes from 'prop-types'
import Color from '@/config/color'

function ConfirmDialog({ open, callback, refreshData }) {
  const { dispatch } = React.useContext(UIContext)

  const handleConfirm = async () => {
    try {
      await callback()
      await refreshData()
    } catch (error) {
      dispatch({
        type: ACTIONS.setToastMessage,
        payload: {
          message: error.response
            ? error.response.data.message
            : 'Something went wrong!',
          type: 'error',
        },
      })
    } finally {
      dispatch({
        type: ACTIONS.toggleLoading,
        payload: false,
      })
      dispatch({
        type: ACTIONS.setConfirmDialog,
        payload: {
          open: false,
          callback: () => {},
          refreshData: () => {},
        },
      })
    }
  }

  const handleClose = () => {
    dispatch({
      type: ACTIONS.setConfirmDialog,
      payload: {
        open: false,
        callback: () => {},
        refreshData: () => {},
      },
    })
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': { backgroundColor: Color.lightgray },
          '& .MuiButton-contained': { backgroundColor: Color.primary },
          '& .MuiButton-outlined': { borderColor: Color.primary, color: Color.primary },
        }}
      >
        <DialogTitle id="alert-dialog-title" className="text-primary">
          Are you sure you want to delete this entry?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The file will be permanently deleted, Please check before confirming
          </DialogContentText>
        </DialogContent>
        <DialogActions className="text-primary">
          <Button variant="outlined" type="primary" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" type="primary" onClick={handleConfirm} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ConfirmDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default ConfirmDialog
