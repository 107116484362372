module.exports = {
  primary: '#0F3C50',
  gray: '#E5E5E5',
  lightgray: '#FAFAFA',
  darkgray: '#787878',
  blue: '#568ca5',
  green: '#68C691',
  purple: '#4061BC',
  lightPurple: '#3C50A0',
  darkPurple: '#3c50a01a',
  black: '#000',
  draft: '#00000033',
  white: '#fff',
}
