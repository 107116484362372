import React from 'react'
import PropTypes from 'prop-types'
import useAuth from '@/hooks/auth'
import { Navigate } from 'react-router-dom'
import MainLayout from '../layout/MainLayout'

function ProtectedRoute({ children, roles }) {
  const { state } = useAuth()
  if (!state.user && !state.token) {
    return <Navigate to="/login" />
  }

  if (state.user && roles.length) {
    if (!roles.includes(state.user.role)) {
      return <Navigate to="/login" />
    }
  }

  return (
    <MainLayout>
      {children}
    </MainLayout>
  )
}

ProtectedRoute.propTypes = {
  children: PropTypes.any.isRequired,
  roles: PropTypes.array,
}

ProtectedRoute.defaultProps = {
  roles: []
}

export default ProtectedRoute
