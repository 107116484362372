import { TOKEN_KEY } from '@/config/app'
import React from 'react'

const initialState = {
  user: null,
  token: localStorage.getItem(TOKEN_KEY) || ''
}

export const ACTIONS = {
  setUser: 'SET_USER',
  setToken: 'SET_TOKEN',
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.setUser:
      return {
        ...state,
        user: action.payload
      }
    case ACTIONS.setToken:
      return {
        ...state,
        token: action.payload
      }
    default:
      return state
  }
}

function useUserAuthStore() {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return {
    state,
    dispatch,
  }
}

export default useUserAuthStore
