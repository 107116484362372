import UserAuthContext from '@/context/userAuthContext'
import useUserAuthStore, { ACTIONS } from '@/store/userAuthStore'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import authApi from '@/api/auth'
import { TOKEN_KEY, UserRole } from '@/config/app'
import { useLocation, useNavigate } from 'react-router-dom'

function AuthProvider({ children }) {
  const { state, dispatch } = useUserAuthStore()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  async function login(email, password) {
    const { accessToken, user } = await authApi.login({ email, password })
    dispatch({ type: ACTIONS.setToken, payload: accessToken })
    dispatch({ type: ACTIONS.setUser, payload: user })
    localStorage.setItem(TOKEN_KEY, accessToken)
  }

  async function logout() {
    dispatch({ type: ACTIONS.setToken, payload: null })
    dispatch({ type: ACTIONS.setUser, payload: null })
    localStorage.removeItem(TOKEN_KEY)
  }

  async function getCurrentUser() {
    try {
      setLoading(true)
      const user = await authApi.getCurrentUser()
      dispatch({ type: ACTIONS.setUser, payload: user })
      if (location.pathname === '/') {
        if (user.role === UserRole.HR || user.role === UserRole.ADMIN) {
          navigate('/survey')
        } else {
          navigate('/spider-diagram')
        }
      } else {
        navigate(location.pathname + location.search)
      }
    } catch (error) {
      if (location.pathname.includes('/view-only-team')) {
        navigate(location.pathname + location.search)
      } else {
        navigate('/login')
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCurrentUser()
  }, [])

  return (
    <UserAuthContext.Provider value={{
      state,
      dispatch,
      login,
      logout,
      loading,
    }}
    >
      {children}
    </UserAuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export default AuthProvider
